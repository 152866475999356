.ant-form-item-control-input-content {
    display: "flex";
    justify-content: "space-evenly";
    gap: "10px";
}

.ant-form-item-control-input-content>input:disabled {
    background-color: rgb(255, 255, 255);
    color: rgb(99, 97, 97);
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    font-size: 15px;
    color: red;
}

.formButtons {
    display: flex;
    justify-content: center;
    gap: 8px;
}

@media screen and (max-width:480px) {
    .formClass {
        margin: 0px 20px;
    }

    .ant-form-item .ant-form-item-label {
        padding-bottom: 0px;
    }

    .ant-col-offset-8 {
        margin-left: 0px;
    }

    .formButtons {
        justify-content: flex-end;
    }
}